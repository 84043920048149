.footer {
    text-align: center;
}

.content {
    margin-top: 30px;
    margin-bottom: 20px;
}


.link {
    color: rgb(33,37,41);
    margin: 15px;
    opacity: 0.9;
}
.link:hover {
    color: rgb(33,37,41);
    opacity: 1.0;
}
@media (max-width: 425px) {
    .links {
        display: grid;
        grid-template-columns: 50% 50%;
    }
}

.logo {
    height: 24px;
    width: 24px;
    margin: 20px;
    opacity: 0.9;
}
.logo:hover {
    opacity: 1.0;
}

.copyright {
    font-size: 0.7em;
    opacity: 0.8;
    padding-bottom: 25px;
}