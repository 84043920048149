html { font-size: 0.97em; }
h1 { font-size: 2.488em; }
h2 { font-size: 2.074em; }
h3 { font-size: 1.728em; }
h4 { font-size: 1.44em; }
h5 { font-size: 1.20em; }
p { font-size: 1.05em; }

body {
    overflow-x: hidden;
}

img {
    pointer-events: none;
    -webkit-user-drag: none;
    -webkit-user-select: none; 
    -moz-user-select: none; 
    -ms-user-select: none;
    user-select: none; 
}

.load {
    position: fixed;
    top: 50vh;
    left: 50vw;
    transform: translate(-50%, -50%);
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {transform: translateY(0);} 
    40% {transform: translateY(-30px);} 
    60% {transform: translateY(-15px);} 
 } 